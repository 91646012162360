import { COMMUNITY_SUPPORT_BOT } from '@zaritalk/constants';

import { SmallButton, ImageMemo, Layout, Typography } from '../index';

export default function DefaultErrorComponent() {
  return (
    <Layout.ColCenter
      css={{
        maxWidth: '$MAX_BROWSER_WIDTH',
        margin: '24px auto',
        padding: '0px 24px',
        safeAreaPadding: { top: true, bottom: true },
      }}
    >
      <section>
        <ImageMemo
          width={'100%'}
          height={'100%'}
          src={'https://static.zaritalk.com/images/img-talklogo-148-42%402x.png'}
          alt={'자리톡 로고'}
        />
      </section>

      <ImageMemo
        src={'https://static.zaritalk.com/images/img-under-maintenance%402x.png'}
        alt={'자리톡 오류 페이지'}
        width={'300px'}
        height={'300px'}
        style={{ margin: '48px 0 23px' }}
      />

      <Typography variant={'16_BOLD_WIDE'} css={{ marginBottom: '12px', whiteSpace: 'pre-wrap', textAlign: 'center' }}>
        {'현재 일시적으로 기능이 원활하지 않습니다.\n고객센터로 문의주시면 빠르게 도와드리겠습니다.'}
      </Typography>
      <SmallButton
        variant={'SECONDARY'}
        css={{ marginBottom: '8px', width: '100%', height: '48px' }}
        onClick={() => window.location.replace(COMMUNITY_SUPPORT_BOT)}
      >
        {'고객센터 문의하기'}
      </SmallButton>
      <SmallButton css={{ width: '100%', height: '48px' }} onClick={() => window.location.replace('/')}>
        {'돌아가기'}
      </SmallButton>
    </Layout.ColCenter>
  );
}

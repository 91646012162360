export const ZARITALK_APP_INSTALLED = 'zaritalkApp';

// Notion ID
export const NOTION_TERMS_OF_SERVICE = 'ef4cfa1ddeb54d31965a94c16d804035';
export const NOTION_PRIVACY_OF_SERVICE = 'e7c27ba4657d4976aed8a46fa06fd7ff';

// Naver Cloud Platform Id
export const NAVER_CLOUD_PLATFORM_ID = '3u5n1wx3tm';

export const COMMUNITY_CATEGORY_TOTAL_CODE = 'TOTAL';
export const COMMUNITY_CATEGORY_HIT_CODE = 'HIT';
export const COMMUNITY_CATEGORY_MAGAZINE_CODE = 'MAGAZINE';
export const COMMUNITY_CATEGORY_RENTUBE_CODE = 'RENTUBE';
export const COMMUNITY_CATEGORY_DIARY_CODE = 'DIARY';

// cohere key
export const COHERE_API_KEY = 'CtbRK7cJ3XeX-Wp-c-S1VNi9';

export const ELECTRONIC_DOCUMENT_IMAGE_BUCKET_NAME = 'electronic-document';
export const COMMUNITY_IMAGE_BUCKET_NAME = 'community';

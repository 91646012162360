import { isBrowser, isIOS, isAndroid } from 'mobile-device-detect';
import { getWindow } from 'ssr-window';

import { ANDROID_STORE, APP_STORE, IOS_STORE, PLAY_STORE } from '@zaritalk/constants';
import { MESSAGE_REQ_TYPE } from '@zaritalk/types';

import { checkTargetAppVersion, isZaritalkApp } from './appInfomation';
import { FILE_DOWNLOAD_UPDATE_VERSION, FONT_SIZE_UPDATE_VERSION } from './constants';

const postMessageToApp = ({ type, data = {} }: { type: string; data?: any }): void => {
  // @ts-ignore
  if (typeof window !== 'undefined' && window.ReactNativeWebView) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: type, data: data }));
  } else {
    // console.warn('앱에서 실행해주세요.');
  }
};

export const checkMultiPermission = ({ permissionTypeList }: { permissionTypeList: string[] }): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.MULTI_PERMISSION_CHECK, data: { permissionTypeList } });
  }, 100);
};

export const requestMultiPermission = ({ permissionTypeList }: { permissionTypeList: string[] }): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.MULTI_PERMISSION_REQ, data: { permissionTypeList } });
  }, 100);
};

export const checkPermission = ({ permissionType }: { permissionType: string }): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.PERMISSION_CHECK, data: { permissionType } });
  }, 100);
};

export const requestPermission = ({ permissionType }: { permissionType: string }): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.PERMISSION_REQ, data: { permissionType } });
  }, 100);
};

export const checkNotiPermission = (): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.NOTI_PERMISSION_CHECK, data: {} });
  }, 100);
};

export const requestNotiPermission = (): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.NOTI_PERMISSION_REQ, data: {} });
  }, 100);
};

export const checkContactsPermission = (): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.CONTACTS_PERMISSION_CHECK, data: {} });
  }, 100);
};

export const requestContactsPermission = (): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.CONTACTS_PERMISSION_REQ, data: {} });
  }, 100);
};

export const checkLocationPermission = (): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.LOCATION_PERMISSION_CHECK, data: {} });
  }, 100);
};

export const requestLocationPermission = (): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.LOCATION_PERMISSION_REQ, data: {} });
  }, 100);
};

export const requestFCMToken = (): void => {
  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.GET_FCM_TOKEN, data: {} });
  }, 100);
};

export const changeTargetUrl = ({ targetUrl }: { targetUrl: string }): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.POST_TARGET_URL, data: { targetUrl } });
};

export const getTargetUrl = (): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.GET_TARGET_URL });
};

export const getAppVersion = (): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.GET_APP_VERSION });
};

export const requestGetFontScale = (): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.GET_FONT_SCALE });
};

export const requestGetDeviceUUID = (): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.GET_DEVICE_UUID });
};

export const moveToOption = (): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.OPEN_SETTING });
};

export const requestVibrate = (data: { pattern?: number[] | number; repeat?: boolean }): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.VIBRATION, data: data });
};

export type ScreenType = 'Detail' | 'FromBottom' | 'Modal';

export const requestPushScreen = ({ url, screenType = 'Detail' }: { url: string; screenType?: ScreenType }): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.PUSH_SCREEN, data: { url, screenType } });
};

export const requestPopScreen = (url = '', replace = true): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.POP_SCREEN, data: { url, replace } });
};

export const requestPopToTopScreen = ({ url = '', replace }: { url?: string; replace?: boolean }): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.POP_TO_TOP_SCREEN, data: { url, replace } });
};

export const requestInAppBrowserScreen = ({ url }: { url: string }): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.OPEN_IN_APP_BROWSER, data: { url } });
};

export const requestUseSystemFont = ({ textZoom }: { textZoom: number }): void => {
  if (!checkTargetAppVersion(FONT_SIZE_UPDATE_VERSION) || !isAndroid) return;

  postMessageToApp({ type: MESSAGE_REQ_TYPE.SET_TEXT_ZOOM, data: { textZoom } });
};

export const requestAnalyticsEventLog = (data: { eventName: string; params?: { [key: string]: any } }): void => {
  if (!data.eventName) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.ANALYTICS_EVENT_LOG, data: data });
};

export const requestAnalyticsScreenViewLog = (data: {
  screen_name: string;
  screen_class?: undefined | string;
}): void => {
  if (!data.screen_name) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.ANALYTICS_SCREEN_VIEW_LOG, data: data });
};

export const requestAnalyticsSetUserId = (data: { id: string | null }): void => {
  if (!data.id) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.ANALYTICS_SET_USER_ID, data: data });
};

export const requestAnalyticsSetUserProperty = (data: { name: string; value: string | null }): void => {
  if (!data.name) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.ANALYTICS_SET_USER_PROPERTY, data: data });
};

export const requestAnalyticsSetUserProperties = (data: { [key: string]: string | null }): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.ANALYTICS_SET_USER_PROPERTIES, data: data });
};

export const requestSubscribeTopic = (data: { topic: string }): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.SUBSCRIBE_TOPIC, data });
};

export const requestUnsubscribeTopic = (data: { topic: string }): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.UNSUBSCRIBE_TOPIC, data });
};

interface ILaunchImageOptions {
  key: string;
  options: {
    selectionLimit?: number;
  };
}

export const requestLaunchCamera = (data: ILaunchImageOptions): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.LAUNCH_CAMERA, data: data });
};

export const requestLaunchImageLibrary = (data: ILaunchImageOptions): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.LAUNCH_IMAGE_LIBRARY, data });
};

export const requestShare = (data: {
  content: {
    message: string;
    url?: string; // ios
    title?: string; // and
  };
  options?: {
    dialogTitle?: string | undefined; // and
    excludedActivityTypes?: Array<string> | undefined; // ios
    tintColor?: string | undefined; // ios
    subject?: string | undefined; // ios
  };
}): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.SHARE, data });
};

export const requestInAppReview = (): void => {
  if (isBrowser) {
    getWindow().open(ANDROID_STORE, '_blank');
    return;
  }

  if (checkTargetAppVersion('1.3.0')) {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.STORE_REVIEW });
  } else if (!checkTargetAppVersion('1.2.7')) {
    getWindow().open(isIOS ? APP_STORE : PLAY_STORE, '_blank');
  } else {
    getWindow().open(isIOS ? IOS_STORE : ANDROID_STORE, '_blank');
  }
};

type HapticFeedbackTypes =
  | 'impactLight'
  | 'impactMedium'
  | 'impactHeavy'
  | 'rigid'
  | 'soft'
  | 'notificationSuccess'
  | 'notificationWarning'
  | 'notificationError';

type HapticOptions = {
  enableVibrateFallback?: boolean;
  ignoreAndroidSystemSettings?: boolean;
};

type HapticDataType = { type: HapticFeedbackTypes; options?: HapticOptions };

export const requestHaptic = (hapticData: HapticDataType): void => {
  if (!checkTargetAppVersion('1.2.4')) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.HAPTIC, data: hapticData });
};

export const requestGetContacts = (): void => {
  if (!checkTargetAppVersion('1.2.5')) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.GET_CONTACTS });
};

export const requestGetLocation = (): void => {
  if (!checkTargetAppVersion('1.4.5')) {
    return;
  }

  setTimeout(() => {
    postMessageToApp({ type: MESSAGE_REQ_TYPE.GET_LOCATION });
  }, 300);
};

export const requestExitApp = (): void => {
  postMessageToApp({ type: MESSAGE_REQ_TYPE.EXIT_APP });
};

export const requestShowProgressBar = (data: { showProgressBar: boolean }): void => {
  if (!checkTargetAppVersion(FONT_SIZE_UPDATE_VERSION)) return;

  postMessageToApp({ type: MESSAGE_REQ_TYPE.SHOW_PROGRESS_BAR, data });
};

export const requestGestureEnabled = (data: { gestureEnabled: boolean }): void => {
  if (!checkTargetAppVersion(FONT_SIZE_UPDATE_VERSION)) return;

  postMessageToApp({ type: MESSAGE_REQ_TYPE.GESTURE_ENABLED, data });
};
interface PushNotificationObject {
  /* Android only properties */
  ticker?: string | undefined;
  showWhen?: boolean | undefined;
  autoCancel?: boolean | undefined;
  largeIcon?: string | undefined;
  largeIconUrl?: string | undefined;
  smallIcon?: string | undefined;
  bigText?: string | undefined;
  subText?: string | undefined;
  bigPictureUrl?: string | undefined;
  bigLargeIcon?: string | undefined;
  bigLargeIconUrl?: string | undefined;
  color?: string | undefined;
  vibrate?: boolean | undefined;
  vibration?: number | undefined;
  tag?: string | undefined;
  group?: string | undefined;
  groupSummary?: boolean | undefined;
  ongoing?: boolean | undefined;
  priority?: 'max' | 'high' | 'low' | 'min' | 'default' | undefined;
  visibility?: 'private' | 'public' | 'secret' | undefined;
  importance?: 'default' | 'max' | 'high' | 'low' | 'min' | 'none' | 'unspecified' | undefined;
  ignoreInForeground?: boolean | undefined;
  shortcutId?: string | undefined;
  channelId?: string | undefined;
  onlyAlertOnce?: boolean | undefined;
  allowWhileIdle?: boolean | undefined;
  timeoutAfter?: number | null | undefined;
  messageId?: string | undefined;

  when?: number | null | undefined;
  usesChronometer?: boolean | undefined;

  actions?: string[] | undefined;
  invokeApp?: boolean | undefined;

  /* iOS only properties */
  category?: any;

  /* iOS and Android properties */
  id?: string | number | undefined;
  title?: string | undefined;
  message: string;
  picture?: string | undefined;
  userInfo?: { redirectUrl: string };
  playSound?: boolean | undefined;
  soundName?: string | undefined;
  number?: string | number | undefined;
  repeatType?: 'week' | 'day' | 'hour' | 'minute' | 'time' | undefined;
  repeatTime?: number | undefined;
}

interface PushNotificationScheduleObject extends PushNotificationObject {
  date: Date;
  allowWhileIdle?: boolean | undefined;
}

export const requestLocalPush = (localPushData: PushNotificationObject): void => {
  if (!checkTargetAppVersion('1.3.2')) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.LOCAL_PUSH, data: localPushData });
};

export const requestLocalPushSchedule = (localPushScheduleData: PushNotificationScheduleObject): void => {
  if (!checkTargetAppVersion('1.3.2')) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.LOCAL_PUSH_SCHEDULE, data: localPushScheduleData });
};

export const openUrl = (url: string, feature = 'noopener,noreferrer') => {
  if (isZaritalkApp()) {
    checkTargetAppVersion('1.2.0') ? requestInAppBrowserScreen({ url }) : getWindow().open(url, '_self', feature);
  } else {
    getWindow().open(url, '_blank', feature);
  }
};

export const requestFileDownload = (fileUrl: string, fileName: string): void => {
  if (!checkTargetAppVersion(FILE_DOWNLOAD_UPDATE_VERSION)) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.FILE_DOWNLOAD, data: { fileUrl, fileName } });
};

export const requestAdvertisingIdentifier = (): void => {
  if (!isZaritalkApp()) {
    return;
  }

  postMessageToApp({ type: MESSAGE_REQ_TYPE.ADVERTISING_IDENTIFIER, data: {} });
};

import { useQuery } from '@tanstack/react-query';

import { ZARITALK_TOKEN } from '@zaritalk/constants';
import { useCurrentDateState } from '@zaritalk/global-state';
import { IBuildingInfo } from '@zaritalk/types';
import { parseDateToString, getCookieItem } from '@zaritalk/utils/index';

import { ApiReturn, getWithToken } from '../../../utils';

export const generateMonthlyPaymentHistoriesKey = (currentDate: Date) =>
  `/lessor/paymentHistories/new?yearMonth=${parseDateToString(currentDate, '-')}`;

export const fetchMonthlyPaymentHistories = (currentDate: Date) =>
  getWithToken<Array<IBuildingInfo>>(generateMonthlyPaymentHistoriesKey(currentDate));

export const useMonthlyPaymentHistories = () => {
  const { currentDate } = useCurrentDateState();
  const token = getCookieItem(ZARITALK_TOKEN);

  const { data, error, isLoading } = useQuery<ApiReturn<Array<IBuildingInfo>>>(
    [generateMonthlyPaymentHistoriesKey(currentDate)],
    () => fetchMonthlyPaymentHistories(currentDate),
    {
      enabled: !!token,
    },
  );

  return {
    data: data?.data ?? [],
    isLoading,
    error,
  };
};

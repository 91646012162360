import axios, { AxiosResponse } from 'axios';
import { GetServerSidePropsContext } from 'next';
import { getDocument, getWindow } from 'ssr-window';

import {
  SLACK_API_REPORT_URL,
  SLACK_EXTERNAL_API_REPORT_URL,
  SLACK_INTERNAL_API_REPORT_URL,
} from '@zaritalk/constants';
import { getCookieItem, getCookies } from '@zaritalk/utils/lib/cookie';
import { getBrowserVendor, getDeviceType, isClientSide } from '@zaritalk/utils/lib/device';
import { noop } from '@zaritalk/utils/lib/operation';
import { parseJWT } from '@zaritalk/utils/lib/session';

import { EXCLUDING_REPORTING_LIST, TOKEN_MATCHED_APP_TYPE } from '../constants/report';

type TApiSource = 'backend' | 'internal' | 'external';

const ERROR_REPORT_TYPE_MATCHED_URL = {
  backend: SLACK_API_REPORT_URL,
  internal: SLACK_INTERNAL_API_REPORT_URL,
  external: SLACK_EXTERNAL_API_REPORT_URL,
};

export const slackReportFetcher = (
  httpMethod: string,
  apiPath: string,
  requestData: any,
  response: AxiosResponse,
  ctx?: GetServerSidePropsContext,
  reportType: TApiSource = 'backend',
) => {
  try {
    if (
      checkReportExclusion({
        apiPath,
        httpMethod,
        statusCode: response?.status,
      })
    )
      return new Promise((resolve) => resolve);

    const slackFormData = createSlackReportFormData({ httpMethod, apiPath, requestData, response, ctx });
    const slackReportMessage = createSlackReportText(slackFormData);

    return axios
      .post(
        ERROR_REPORT_TYPE_MATCHED_URL[reportType],
        { text: slackReportMessage },
        { headers: { 'Content-type': 'text/plain' } },
      )
      .catch(noop);
  } catch {
    return new Promise((resolve) => resolve);
  }
};

export type AppType = 'lessor' | 'lessee' | 'realtor' | 'community' | 'admin' | 'ect';

interface ISlackReportFormData {
  token: string;
  role: string;
  pk: number;

  triggerLocation: 'SERVER' | 'CLIENT';

  httpMethod: string;
  statusCode: string | number;
  apiPath: string;

  currentURI: string;
  prevURI: string;

  requestData: string;
  errorMessage: string;

  browserInfo: string;
  deviceInfo: string;
  userAgent: string;

  appType: AppType;
  cookie: string;
}

const createSlackReportFormData = ({
  httpMethod,
  apiPath,
  requestData,
  response,
  ctx,
}: ICreateSlackReportFormData): ISlackReportFormData => {
  const agent = isClientSide() ? getWindow().navigator.userAgent : ctx?.req.headers['user-agent'] ?? '';
  const loginToken = isClientSide()
    ? getCookieItem(process.env.LOGIN_TOKEN_NAME as string, false)
    : ctx?.req.cookies[process.env.LOGIN_TOKEN_NAME as string] ?? '';
  const { PK, role } = parseJWT(loginToken);

  return {
    token: loginToken !== '' ? loginToken : '로그인 안한 상태',
    role: role !== '' ? role : '로그인 안한 상태',
    pk: PK,
    triggerLocation: isClientSide() ? 'CLIENT' : 'SERVER',

    httpMethod,
    statusCode: response?.status || '전달된 상태값이 없음',
    apiPath,

    currentURI: isClientSide() ? getWindow().location.href : ctx?.req?.headers.host ?? '' + ctx?.resolvedUrl ?? '',
    prevURI: isClientSide() ? getDocument().referrer : ctx?.req?.headers.referer ?? '',

    requestData: requestData ? JSON.stringify(requestData) : '전달된 데이터 없음',
    errorMessage: response ? JSON.stringify(response.data) : '받은 메세지 없음',

    browserInfo: agent !== '' ? getBrowserVendor(agent) : '전달된 브라우저 정보 없음',
    deviceInfo: agent !== '' ? getDeviceType(agent) : '전달된 기기정보 없음',
    userAgent: agent,

    appType: TOKEN_MATCHED_APP_TYPE[process.env.LOGIN_TOKEN_NAME as string] ?? 'ect',
    cookie: JSON.stringify(getCookiesIncludeSpecificWord('zari', ctx)),
  };
};

interface ICreateSlackReportFormData {
  httpMethod: string;
  apiPath: string;
  requestData: any;
  response: AxiosResponse;
  ctx?: GetServerSidePropsContext;
}

const createSlackReportText = (formData: ISlackReportFormData): string => {
  return `
================================================
- APP 타입 : ${formData.appType}
- 사용자 토큰 : ${formData.token}
- 사용자 타입 : ${formData.role}
- 사용자 PK : ${formData.pk}
- 쿠키 : ${formData.cookie}
- 브라우저 정보: ${formData.browserInfo}
- 디바이스 정보: ${formData.deviceInfo}
- USER_AGENT(유저 에이전트) : ${formData.userAgent}
- 에러 발생 위치 : ${formData.triggerLocation}
- 요청 HTTP 메소드 : ${formData.httpMethod}
- 응답 상태 : ${formData.statusCode}
- 에러가 발생한 API URL : ${formData.apiPath}
- 현재 보는 페이지 URL : ${formData.currentURI}
- 전에 보던 페이지 URL : ${formData.prevURI}
- 서버로 보낸 데이터 : ${formData.requestData}
- 받은 에러 메세지 : ${formData.errorMessage}`;
};

interface CheckReportExclusionProps {
  httpMethod: string;
  apiPath: string;
  statusCode?: number;
}

const checkReportExclusion = ({ httpMethod, apiPath, statusCode }: CheckReportExclusionProps): boolean => {
  return EXCLUDING_REPORTING_LIST.some((excludingItem) => {
    const isIncludeUrl = apiPath?.includes(excludingItem?.includedUrl);
    const isSameHttpMethod = httpMethod === excludingItem?.httpMethod;
    const isSameStatusCode = !excludingItem?.statusCode || statusCode === excludingItem?.statusCode;

    return isIncludeUrl && isSameHttpMethod && isSameStatusCode;
  });
};

type CookieType = Partial<{ [p: string]: string }>;

const getCookiesIncludeSpecificWord = (specificWord: string, ctx?: GetServerSidePropsContext) => {
  const cookiesIncludeSpecificWord: CookieType = {};
  let cookies: CookieType = {};
  if (isClientSide()) {
    cookies = getCookies();
  } else {
    if (ctx) {
      cookies = ctx.req.cookies;
    }
  }

  Object.keys(cookies).map((key: string) => {
    if (key.toLowerCase()?.includes(specificWord)) {
      cookiesIncludeSpecificWord[key] = cookies[key];
    }
  });

  return cookiesIncludeSpecificWord;
};

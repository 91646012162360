import { styled } from '../stitches.config';

type LineHeight = 'NARROWER' | 'NARROW' | 'WIDE' | 'WIDER';
type FontSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | number;
type FontWeight = 'ExtraBold' | 'Bold' | 'Medium' | 'Regular' | 'Light';

const LineHeightFormat = new Map<LineHeight, `${number}%`>([
  ['NARROWER', '100%'],
  ['NARROW', '125%'],
  ['WIDE', '136%'],
  ['WIDER', '164%'],
]);

const FontSizeFormat = new Map<FontSize, `${number}px`>([
  ['h1', '28px'],
  ['h2', '22px'],
  ['h3', '20px'],
  ['h4', '18px'],
  ['h5', '16px'],
  ['h6', '14px'],
  [28, '28px'],
  [22, '22px'],
  [20, '20px'],
  [18, '18px'],
  [16, '16px'],
  [14, '14px'],
  [12, '12px'],
  [10, '10px'],
]);

const FontWeightFormat = new Map<FontWeight, number>([
  ['ExtraBold', 800],
  ['Bold', 700],
  ['Medium', 500],
  ['Regular', 400],
  ['Light', 300],
]);

const fontSizeVariants = [...FontSizeFormat.entries()].reduce((acc, [key, value]) => {
  return { ...acc, [key]: { fontSize: value } };
}, {} as { [key in FontSize]: { fontSize: `${number}px` } });

const lineHeightVariants = [...LineHeightFormat.entries()].reduce((acc, [key, value]) => {
  return { ...acc, [key]: { lineHeight: value } };
}, {} as { [key in LineHeight]: { lineHeight: `${number}%` } });

const fontWeightVariants = [...FontWeightFormat.entries()].reduce((acc, [key, value]) => {
  return { ...acc, [key]: { fontWeight: value } };
}, {} as { [key in FontWeight]: { fontWeight: number } });

const LegacyTypography = styled('span', {
  fontSize: '14px',
  lineHeight: '164%',
  fontWeight: 500,

  variants: {
    is: {
      h1: {
        display: 'inline-block',
        fontSize: FontSizeFormat.get('h1'),
        lineHeight: LineHeightFormat.get('NARROW'),
        fontWeight: FontWeightFormat.get('Bold'),
      },
      h2: {
        display: 'inline-block',
        fontSize: FontSizeFormat.get('h2'),
        lineHeight: LineHeightFormat.get('NARROW'),
        fontWeight: FontWeightFormat.get('Bold'),
      },
      h3: {
        display: 'inline-block',
        fontSize: FontSizeFormat.get('h3'),
        lineHeight: LineHeightFormat.get('WIDE'),
        fontWeight: FontWeightFormat.get('Bold'),
      },
      h4: {
        display: 'inline-block',
        fontSize: FontSizeFormat.get('h4'),
        lineHeight: LineHeightFormat.get('NARROW'),
        fontWeight: FontWeightFormat.get('Bold'),
      },
      h5: {
        display: 'inline-block',
        fontSize: FontSizeFormat.get('h5'),
        lineHeight: LineHeightFormat.get('NARROW'),
        fontWeight: FontWeightFormat.get('Bold'),
      },
      h6: {
        display: 'inline-block',
        fontSize: FontSizeFormat.get('h6'),
        lineHeight: LineHeightFormat.get('NARROW'),
        fontWeight: FontWeightFormat.get('Bold'),
      },
    },

    fontSize: fontSizeVariants,
    lineHeight: lineHeightVariants,
    fontWeight: fontWeightVariants,

    inline: {
      true: {
        display: 'inline-block',
        width: '100%',
      },
    },

    textAlign: {
      left: { textAlign: 'left' },
      center: { textAlign: 'center' },
      right: { textAlign: 'right' },
    },
  },
});

export default LegacyTypography;

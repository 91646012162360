import { memo, ReactElement } from 'react';

import { SVGIconProps } from '../';

const NavMoreIcon = memo(({ fill = '#000', height = '24px', className, onClick }: SVGIconProps): ReactElement => {
  return (
    <svg
      className={className}
      height={height}
      fill={fill}
      onClick={onClick}
      viewBox={'0 0 24 24'}
      style={{ display: 'inline-block', verticalAlign: 'middle' }}
    >
      <g fillRule={'evenodd'} transform={'translate(4,5)'}>
        <path
          d={
            'M15 12c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1v-1c0-.552.448-1 1-1h14zm0-6c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V7c0-.552.448-1 1-1h14zm0-6c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h14z'
          }
        />
      </g>
    </svg>
  );
});

export default NavMoreIcon;

import { Hydrate, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { getWindow } from 'ssr-window';

import { APPLICATION_ID_OBJECT, ZARITALK_HOST_URL } from '@zaritalk/constants';
import { LESSOR_NAVIGATION_ROUTE_ORDERS } from '@zaritalk/domain/common/components/BottomNav/LessorBottomNav';
import { isZaritalkApp } from '@zaritalk/protocol';
import { GlobalErrorBoundary, globalStyles, PageTransitionLayout } from '@zaritalk/ui-components/index';
import { clientSideApiErrorHandler, removeAMPTestCookie } from '@zaritalk/utils/index';

import { ZaritalkQATools } from '../components';

import '@ungap/global-this';
import 'swiper/scss/zoom';

import '../styles/channel-talk.scss';
import '../styles/global.scss';
import '../styles/navigation.scss';
import '../styles/notion-style.scss';
import '../styles/pagination.scss';
import '../styles/swipper.scss';

const InitPermissionPopup = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.InitPermissionPopup;
  },
  { ssr: false },
);

const InducePushPermission = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.InducePushPermission;
  },
  { ssr: false },
);

const RootLoadingLayer = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.RootLoadingLayer;
  },
  { ssr: false },
);

const AppAuthorLayer = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.AppAuthorLayer;
  },
  { ssr: false },
);

const InduceAppInstall = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.InduceAppInstall;
  },
  { ssr: false },
);

const AlertLayer = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.AlertLayer;
  },
  { ssr: false },
);

const ToastLayer = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.ToastLayer;
  },
  { ssr: false },
);

const VisitLayer = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.VisitLayer;
  },
  { ssr: false },
);

const GoogleTagManagerLayer = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.GoogleTagManagerLayer;
  },
  { ssr: false },
);

const GlobalLessorCohereLayer = dynamic(
  async () => {
    const mod = await import('@zaritalk/cohere');
    return mod.GlobalLessorCohereLayer;
  },
  { ssr: false },
);

const NotAdminBlockPopup = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.NotAdminBlockPopup;
  },
  { ssr: false },
);

const BottomNav = dynamic(
  async () => {
    const mod = await import('@zaritalk/domain/common');
    return mod.LessorBottomNav;
  },
  { ssr: false },
);

if (typeof window !== 'undefined' && getWindow().location.origin === ZARITALK_HOST_URL) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function () {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.error = function () {};
}

globalStyles();

declare global {
  const eruda: any;
}

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: clientSideApiErrorHandler,
        }),
      }),
  );

  useEffect(() => {
    removeAMPTestCookie();
  }, []);

  useEffect(() => {
    if (process.env.API_ENV === 'localhost' && isZaritalkApp() && typeof eruda === 'undefined') {
      const script = document.createElement('script');

      script.src = 'https://cdn.jsdelivr.net/npm/eruda';
      script.onload = () => {
        if (typeof eruda !== 'undefined') {
          // @ts-ignore
          eruda.init();
        }
      };

      document.body.appendChild(script);
      return () => script.remove();
    }
  }, []);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_API_MOCKING) {
      if (typeof window === 'undefined') {
        const server = import('@zaritalk/mock');
        server.then((s) => s.server.listen());
      } else {
        const worker = import('@zaritalk/mock');
        worker.then((w) => w.worker.start().then((r) => console.log('mock ====> ', r)));
      }
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet={'UTF-8'} />
        <meta name={'robots'} content={'index, follow'} />
        <meta
          name={'viewport'}
          content={'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover'}
        />
        <meta name={'theme-color'} content={'#f8f8f7'} />

        <meta name={'facebook-domain-verification'} content={'suy8hk4jk406i860juyarufngn8ufd'} />
        <meta name={'naver-site-verification'} content={'f802e572bc4eb882472ec3161cf1a7f82ab24ec1'} />

        <link rel={'shortcut icon'} href={'https://static.zaritalk.com/favicon.ico'} />
        <link
          rel={'icon'}
          type={'image/png'}
          sizes={'192x192'}
          href={'https://static.zaritalk.com/favicon/android-chrome-192x192.png'}
        />
        <link
          rel={'icon'}
          type={'image/png'}
          sizes={'256x256'}
          href={'https://static.zaritalk.com/favicon/android-chrome-256x256.png'}
        />
        <link
          rel={'icon'}
          type={'image/png'}
          sizes={'32x32'}
          href={'https://static.zaritalk.com/favicon/favicon-32x32.png'}
        />
        <link
          rel={'icon'}
          type={'image/png'}
          sizes={'16x16'}
          href={'https://static.zaritalk.com/favicon/favicon-16x16.png'}
        />
        <link
          rel={'apple-touch-icon'}
          sizes={'180x180'}
          href={'https://static.zaritalk.com/favicon/apple-touch-icon.png'}
        />
        <link rel={'mask-icon'} href={'https://static.zaritalk.com/favicon/safari-pinned-tab.svg'} color={'#2c7fff'} />
        <link
          rel={'preload'}
          as={'style'}
          type={'text/css'}
          href={'https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css'}
        />
        <link
          rel={'stylesheet'}
          type={'text/css'}
          href={'https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css'}
        />
        <meta name={'msapplication-TileColor'} content={'#f8f8f7'} />
        <script
          type={'application/ld+json'}
          dangerouslySetInnerHTML={{ __html: JSON.stringify(APPLICATION_ID_OBJECT) }}
        />
      </Head>

      <GlobalErrorBoundary>
        <JotaiProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.REACT_QUERY_DEV_TOOLS === 'true' && (
              <ReactQueryDevtools toggleButtonProps={{ style: { opacity: 0.2 } }} />
            )}
            {process.env.API_ENV === 'stage' && <ZaritalkQATools />}
            <Hydrate state={pageProps.dehydratedState}>
              <PageTransitionLayout navigationRouteOrder={LESSOR_NAVIGATION_ROUTE_ORDERS}>
                <Component {...pageProps} />
              </PageTransitionLayout>
              <BottomNav />
              <GoogleTagManagerLayer />
              <GlobalLessorCohereLayer />
              <InitPermissionPopup />
              <ToastLayer timeout={2000} />
              <VisitLayer />
              <InducePushPermission />
              <InduceAppInstall />
              <RootLoadingLayer />
              <AppAuthorLayer />
              <AlertLayer />
              <NotAdminBlockPopup prodUrl={ZARITALK_HOST_URL} />
            </Hydrate>
          </QueryClientProvider>
        </JotaiProvider>
      </GlobalErrorBoundary>
    </>
  );
}

export default MyApp;

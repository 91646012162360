import { ComponentProps } from '@stitches/react';
import { PropsWithChildren } from 'react';

import { TTypographyVariant, TYPOGRAPHY_VARIANTS } from '@zaritalk/constants';
import { KeyOf } from '@zaritalk/types';

import { styled } from '../stitches.config';

interface TypographyProps extends PropsWithChildren, ComponentProps<typeof StyledTypography> {
  variant: TTypographyVariant;
  as?: KeyOf<HTMLElementTagNameMap>;
}

export default function Typography({ variant, children, as, ...restProps }: TypographyProps) {
  return (
    <StyledTypography variant={variant} as={as} {...restProps}>
      {children}
    </StyledTypography>
  );
}

const StyledTypography = styled('span', {
  display: 'inline-block',

  variants: {
    variant: TYPOGRAPHY_VARIANTS,
    underline: {
      true: {
        textDecoration: 'underline',
      },
    },
  },

  defaultVariants: {
    underline: false,
  },
});

export interface ResponseDate {
  result: any;
  status: string;
  key?: string;
}

export interface ResponseMessage {
  type: string;
  data: ResponseDate;
}

const MESSAGE_TYPES = [
  'EXCEPTION', // 예외 상황
  'ALL_PERMISSION_CHECK', // 모든 권한 여부 확인
  'ALL_PERMISSION_REQ', // 모든 권한 요청
  'MULTI_PERMISSION_CHECK', // 다수 권한 여부 확인
  'MULTI_PERMISSION_REQ', // 다수 권한 요청
  'PUSH_SCREEN', // 새로운 screen 열기
  'POP_SCREEN', // screen 닫기
  'POP_TO_TOP_SCREEN', // screen 최상위로 닫기
  'PERMISSION_CHECK', // 권한 여부 확인
  'PERMISSION_REQ', // 권한 요청
  'NOTI_PERMISSION_REQ', // Push 권한 요청
  'NOTI_PERMISSION_CHECK', // Push 권한 확인
  'CONTACTS_PERMISSION_REQ', // 연락처 권한 요청
  'CONTACTS_PERMISSION_CHECK', // 연락처 권한 확인
  'LOCATION_PERMISSION_REQ', // 위치 권한 요청
  'LOCATION_PERMISSION_CHECK', // 위치 권한 확인
  'POST_TARGET_URL', // 웹뷰 target url 변경
  'GET_TARGET_URL', // 웹뷰 target url 가져오기
  'GET_APP_VERSION', // 현재 앱 버전 가져오기
  'GET_DEVICE_UUID', // 현재 앱 UUID 가져오기
  'GET_FONT_SCALE', // 현재 앱 시스템 폰트 사이즈 가져오기
  'OPEN_SETTING', // 설정창 열기
  'VIBRATION', // 기기 진동 요청
  'EXIT_APP', // 앱 종료
  'GET_FCM_TOKEN', // FCM Token 가져오기
  'OPEN_IN_APP_BROWSER', // 인앱브라우저로 열기
  'ANALYTICS_EVENT_LOG', // 애널리틱스 이벤트 발송
  'ANALYTICS_SCREEN_VIEW_LOG', // 애널리틱스 현재 페이지 이벤트 발송
  'ANALYTICS_SET_USER_ID', // 애널리틱스 Set userId
  'ANALYTICS_SET_USER_PROPERTY', // 애널리틱스 Set userProperty
  'ANALYTICS_SET_USER_PROPERTIES', // 애널리틱스 Set userProperties
  'CHANGE_URL_IN_TARGET_WEBVIEW', // 웹뷰 URL 변경
  'ADVERTISING_IDENTIFIER', // GET ADID
  'SUBSCRIBE_TOPIC', // Topic 구독
  'UNSUBSCRIBE_TOPIC', // Topic 구독취소
  'SHARE', // 공유하기
  'STORE_REVIEW', // 인앱리뷰
  'HAPTIC', // 햅틱
  'GET_CONTACTS', // 연락처 목록 가져오기
  'GET_LOCATION', // 위치정보 가져오기
  'LOCAL_PUSH', // 로컬 푸시 전송
  'LOCAL_PUSH_SCHEDULE', // 로컬 푸시 예약 전송
  'LAUNCH_CAMERA', // 카메라 열기
  'LAUNCH_IMAGE_LIBRARY', // 사진 라이브러리 열기
  'SHOW_PROGRESS_BAR', // 프로그레스 바 보이기
  'GESTURE_ENABLED', // 스택 네비게이션 제스처 막기
  'SET_TEXT_ZOOM', // [안드로이드] 줌 크기 조절
  'FILE_DOWNLOAD', // 파일 다운로드
] as const;

type TMessageTypes = typeof MESSAGE_TYPES[number];
type TMessageType = {
  [K in TMessageTypes]: string;
};
const MESSAGE_REQ_TYPE: TMessageType = {} as TMessageType;
MESSAGE_TYPES.forEach((messageType) => {
  MESSAGE_REQ_TYPE[messageType] = `REQ_${messageType}`;
});
const MESSAGE_RES_TYPE: TMessageType = {} as TMessageType;
MESSAGE_TYPES.forEach((messageType) => {
  MESSAGE_RES_TYPE[messageType] = `RES_${messageType}`;
});

export { MESSAGE_RES_TYPE, MESSAGE_REQ_TYPE };

import { GetServerSidePropsContext } from 'next';

import { getBrowserVendor, getCookieItem, getDeviceType, isClientSide, noop, parseJWT } from '@zaritalk/utils/index';

import { ActionType, REST_API_URL } from '../../index';
import { FetchReturnType, postWithoutToken, slackReportFetcher } from '../../utils';

// 행위
type ActivityLogAction =
  | 'create'
  | 'modify'
  | 'delete'
  | 'send'
  | 'change'
  | 'click'
  | 'view'
  | 'close'
  | 'deffered_deeplink'
  | 'register'
  | 'completed'
  | 'select'
  | 'off'
  | 'on';

// 행위 대상자
export type ActivityLogActionTarget =
  | 'building'
  | 'room_empty'
  | 'room'
  | 'complete'
  | 'fee'
  | 'lessee'
  | 'lessor'
  | 'contract'
  | 'vacancy'
  | 'post'
  | 'comment'
  | 'recomment'
  | 'app_install'
  | 'app_push'
  | 'share'
  | 'next'
  | 'button'
  | 'lease_report'
  | 'id_card'
  | 'lease_certification'
  | 'lessee_confirm'
  | 'contact'
  | 'contact_addition'
  | 'contact_checking'
  | 'contact_confirmation'
  | 'contact_completion'
  | 'close_contact'
  | 'induce_write'
  | 'induce_diary'
  | 'warning'
  | 'multi_building'
  | 'pay'
  | 'today_zaripay'
  | 'not_today_zaripay'
  | 'alarm'
  | 'community'
  | 'memo'
  | 'bill'
  | 'notice'
  | 'refund'
  | 'payment_history'
  | 'management'
  | 'complaint_reception'
  | 'referrer_popup'
  | 'community_detail_banner'
  | 'paymentdate'
  | 'interest_banner'
  | 'interest_button'
  | 'filter'
  | 'room_count'
  | 'home_vacancy_banner'
  | 'inquiry_button';

// 페이지
type ActivityLogPage =
  | 'host'
  | 'bill'
  | 'memo'
  | 'schedule'
  | 'vacancy'
  | 'community'
  | 'more'
  | 'petition'
  | 'building'
  | 'room'
  | 'kakao_direct'
  | 'onelink'
  | 'presidential-petition'
  | 'lease_report'
  | 'contact'
  | 'pay'
  | 'real-estate-map';
// 페이지 상세
type ActivityLogDetailPage = 'first' | 'detail' | 'success' | 'new' | 'onelink' | 'start';

type ICreateActionType = {
  action: ActivityLogAction;
  actionTarget: ActivityLogActionTarget;
  page: ActivityLogPage;
  detailPage?: ActivityLogDetailPage;
};

export const createActionType = ({
  action,
  actionTarget,
  page,
  detailPage,
}: ICreateActionType): ActionType | string => {
  return detailPage
    ? `${action.toUpperCase()}_${actionTarget.toUpperCase()}/${page.toUpperCase()}_${detailPage.toUpperCase()}`
    : `${action.toUpperCase()}_${actionTarget.toUpperCase()}/${page.toUpperCase()}`;
};

interface IActivityLogData {
  accountPK: number;
  accountType: string;
  actionType: ActionType | string;
  queryString: string;
  requestURL: string;
  browser: string;
  device: string;
}

interface IActivityLog {
  actionType: ActionType | string;
  reqQueryString?: string;
  token?: string;
  ctx?: GetServerSidePropsContext;
}

export const postActivityLog = ({ actionType, reqQueryString, token = '', ctx }: IActivityLog): FetchReturnType => {
  try {
    if (isClientSide()) {
      const autoToken = getCookieItem(process.env.LOGIN_TOKEN_NAME as string, token);
      const tokenInfo = parseJWT(autoToken);

      const searchParams = new URLSearchParams(reqQueryString ?? window.location.search);

      if (searchParams.has('n_query')) {
        searchParams.set('search_keyword', searchParams.get('n_query') as string);
      }

      if (!searchParams.has('referer') || searchParams.get('referer') === '') {
        if (document?.referrer) {
          searchParams.set('referer', document.referrer);
        }
      }

      const requestActivityLogData: IActivityLogData = {
        accountPK: tokenInfo.PK,
        accountType: tokenInfo.role !== '' ? tokenInfo.role : 'NONE',
        actionType,
        queryString: searchParams.toString(),
        requestURL: `${window?.location?.origin}${window?.location?.pathname}` ?? '',
        browser: getBrowserVendor() ?? '',
        device: getDeviceType() ?? '',
      };

      return postWithoutToken(REST_API_URL.ACTIVITY_LOG, requestActivityLogData);
    } else if (ctx) {
      const req = ctx.req;
      const { headers, cookies, url: requestUrl } = req;
      const query = ctx.query;
      const userAgent = headers['user-agent'] ?? '';
      const hostName = headers.host ?? '';
      const prevUrl = headers.referer ?? '';

      const autoToken = cookies[process.env.LOGIN_TOKEN_NAME as string] ?? token;
      const tokenInfo = parseJWT(autoToken);

      const queryString = Object.entries(query)
        .map((e) => (e[0] === 'n_query' ? `search_keyword=${e[1]}` : e.join('=')))
        .join('&');

      const requestActivityLogData: IActivityLogData = {
        accountPK: tokenInfo.PK,
        accountType: tokenInfo.role !== '' ? tokenInfo.role : 'NONE',
        actionType,
        queryString: `referer=${prevUrl}&${queryString}`,
        requestURL: hostName + requestUrl?.split('?')[0],
        browser: getBrowserVendor(userAgent),
        device: getDeviceType(userAgent),
      };

      return postWithoutToken(REST_API_URL.ACTIVITY_LOG, requestActivityLogData);
    } else {
      return new Promise((resolve) => resolve({ data: null, message: 'fail send activity log', status: 400 }));
    }
  } catch (error) {
    const typedError = error as Error;
    slackReportFetcher(
      'GET',
      '/slack/report',
      {},
      {
        config: {},
        headers: {},
        status: 0,
        statusText: '',
        data: typedError?.message ?? 'fail send activity log',
      },
    ).catch(noop);
    return new Promise((resolve) =>
      resolve({
        data: null,
        message: typedError?.message ?? 'fail send activity log',
        status: 400,
      }),
    );
  }
};

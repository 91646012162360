import { styled } from '../stitches.config';

export const Center = styled('section', {
  position: 'absolute',
  top: '50%',
  left: '50%',

  transform: 'translate(-50%, -50%)',
});

// Column
export const ColCenter = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
});

export const ColStartCenter = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '100%',
});

export const ColEndCenter = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',

  width: '100%',
});

export const ColBetweenCenter = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  width: '100%',
});

export const ColBetweenStart = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'start',

  width: '100%',
});

export const ColEvenlyCenter = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',

  width: '100%',
});

export const ColAroundCenter = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',

  width: '100%',
});

export const ColStretchCenter = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  alignItems: 'center',

  width: '100%',
});

export const ColCenterStart = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',

  width: '100%',
});

export const ColCenterEnd = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',

  width: '100%',
});

export const ColCenterBetween = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'space-between',

  width: '100%',
});

export const ColCenterEvenly = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'space-evenly',

  width: '100%',
});

export const ColCenterAround = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'space-around',

  width: '100%',
});

export const ColCenterStretch = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',

  width: '100%',
});

export const ColStartStart = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  width: '100%',
});

export const ColStartEnd = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',

  width: '100%',
});

export const ColStartStretch = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',

  width: '100%',
});

// Row
export const RowCenter = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
});

export const RowStartCenter = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '100%',
});

export const RowEndCenter = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',

  width: '100%',
});

export const RowBetweenCenter = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  width: '100%',
});

export const RowBetweenStart = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',

  width: '100%',
});

export const RowBetweenEnd = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',

  width: '100%',
});

export const RowEvenlyCenter = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',

  width: '100%',
});

export const RowAroundCenter = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',

  width: '100%',
});

export const RowStretchCenter = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  alignItems: 'center',

  width: '100%',
});

export const RowCenterStart = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',

  width: '100%',
});

export const RowCenterEnd = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-end',

  width: '100%',
});

export const RowCenterEvenly = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'space-evenly',

  width: '100%',
});

export const RowCenterAround = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'space-around',

  width: '100%',
});

export const RowCenterStretch = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'stretch',

  width: '100%',
});

export const RowStartStart = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  width: '100%',
});

export const RowStartStretch = styled('section', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'stretch',

  width: '100%',
});

export default {
  Center,

  // Col
  ColCenter,
  ColStartCenter,
  ColEndCenter,
  ColBetweenCenter,
  ColEvenlyCenter,
  ColAroundCenter,
  ColStretchCenter,
  ColCenterStart,
  ColCenterEnd,
  ColCenterBetween,
  ColCenterEvenly,
  ColCenterAround,
  ColCenterStretch,
  ColStartStart,

  // Row
  RowCenter,
  RowStartCenter,
  RowEndCenter,
  RowBetweenCenter,
  RowBetweenStart,
  RowEvenlyCenter,
  RowAroundCenter,
  RowStretchCenter,
  RowCenterStart,
  RowCenterEnd,
  RowCenterEvenly,
  RowCenterAround,
  RowCenterStretch,
  RowStartStart,
};

import { useState, memo, CSSProperties, ComponentProps, useEffect } from 'react';
import { Property } from '@stitches/react/types/css';

import { styled } from '../stitches.config';

interface ImageProps {
  className?: string;
  src: string;
  alt: string;
  height?: string;
  objectFit?: Property.ObjectFit;
  width?: string;
  style?: CSSProperties;
  onClick?: () => void;
  onLoad?: () => void;
  loading?: 'eager' | 'lazy';
}

export default memo(function ImageMemo({
  width = '1px',
  height,
  className = '',
  src,
  alt = '',
  style = {},
  onClick = () => {},
  onLoad = () => {},
  objectFit = 'fill',
  loading = 'eager',
  ...restProps
}: ImageProps & ComponentProps<typeof Img>) {
  const [imgLoading, setLoading] = useState<boolean>(true);
  const [currentSrc, updateSrc] = useState<string | undefined>(undefined);
  const [imgHeight, setImgHeight] = useState<number>(0);

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.onload = () => {
      updateSrc(src);
    };
    imageToLoad.onerror = () => {
      setLoading(false);
    };
    imageToLoad.src = src;

    return () => {
      imageToLoad.src = '';
      imageToLoad.onload = () => ({});
      imageToLoad.onerror = () => ({});
    };
  }, [src]);

  return (
    <Img
      className={className}
      src={currentSrc}
      style={{
        opacity: imgLoading ? 0.5 : 1,
        transition: 'opacity .2s linear, background .1s linear',
        backgroundColor: 'transparent',
        width,
        height: height ?? `${imgHeight}px`,
        minWidth: width,
        minHeight: height ?? '1px',
        objectFit: objectFit,
        ...style,
      }}
      loading={loading}
      alt={imgLoading ? '' : alt}
      onClick={onClick}
      onLoad={(e: any) => {
        const imgRatio = e.target.naturalWidth / e.target.naturalHeight;
        const height = e.target.width / imgRatio;
        setImgHeight(height);
        setLoading(false);
        onLoad();
      }}
      {...restProps}
    />
  );
});

const Img = styled('img', {
  fontSize: '12px',
});

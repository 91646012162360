import { styled } from '../../stitches.config';

const SmallButton = styled('button', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',

  transition: 'background-color 0.3s, color 0.3s, opacity 0.1s',
  outline: 'none',

  backgroundColor: '$white',
  borderRadius: '4px',
  border: 'solid 1px $gray03',

  cursor: 'pointer',
  userSelect: 'none',

  margin: 0,
  padding: '4px',

  '&:hover, :active': {
    opacity: 0.8,
  },

  variants: {
    variant: {
      PRIMARY: {
        backgroundColor: '$primary01',
        border: 'solid 1px $primary01',

        color: '$white',
        cursor: 'pointer',

        '&:active': {
          opacity: 0.8,
        },
      },
      SECONDARY: {
        backgroundColor: '$white',
        border: 'solid 1px $gray03',

        color: '$gray05',
        cursor: 'pointer',

        '&:active': {
          opacity: 0.8,
        },
      },
      THIRD: {
        backgroundColor: '$primary02',

        color: '$primary01',
        cursor: 'pointer',

        '&:active': {
          opacity: 0.8,
        },
      },
      WARNING: {
        backgroundColor: '$white',
        border: 'solid 1px $red',

        padding: '10px 28px',
        fontSize: '12px',
        fontWeight: 'bold',
        color: '$red',
        cursor: 'pointer',

        '&:active': {
          opacity: 0.8,
        },
      },
    },
    disabled: {
      true: {
        backgroundColor: '$gray02',
        border: 'solid 1px $gray02',

        color: '$gray04',
        cursor: 'none',
      },
    },
    position: {
      TOP: {
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
      },
      BOTTOM: {
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
      },
      LEFT: {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
      },
      RIGHT: {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      },
      CENTER: {
        borderRadius: 0,
      },
      DEFAULT: {
        borderRadius: '8px',
      },
    },
  },
  defaultVariants: {
    variant: 'PRIMARY',
    position: 'DEFAULT',
  },
});

export default SmallButton;

import { createStitches, globalCss, keyframes } from '@stitches/react';

import { TTypographyVariant, TYPOGRAPHY_VARIANTS } from '@zaritalk/constants';

const blinkEffect = keyframes({
  '50%': {
    opacity: 0.2,
  },
});

export const openAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(-50%) translateY(150px)',
  },
  '20%': {
    opacity: 0.5,
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(-50%) translateY(0px)',
  },
});

export const globalStyles = globalCss({
  'html, body, div, LegacyTypography, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, tt, var,b, u, i, center,dl, dt, dd, ol, ul, li,fieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, embed,figure, figcaption, footer, header, hgroup,menu, nav, output, ruby, section, summary,time, mark, audio, video, sup':
    {
      margin: 0,
      padding: 0,
      border: 0,
      fontSize: '100%',
      font: 'inherit',
      verticalAlign: 'baseline',
    },

  'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section': {
    display: 'block',
  },

  '*': {
    boxSizing: 'border-box',

    fontFamily:
      'Apple SD Gothic Neo, Spoqa Han Sans, Noto Sans Korean, Apple Gothic, NanumBarunGothic, NanumGothic, Malgun Gothic, sans-serif',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontDisplay: 'swap',

    // Remove scroll style 스크롤 스타일 제거
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    touchAction: 'manipulation',
    '-webkit-overflow-scrolling': 'touch',
    '-webkit-tap-highlight-color': 'transparent',

    '&::-webkit-scrollbar': {
      display: 'none' /* Chrome, Safari, Opera*/,
    },

    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-o-user-select': 'none',
    userSelect: 'none',
  },

  'html, body': {
    width: '100%',
    padding: 0,
    margin: 0,
    background: '#f8f8f8',
    fontFamily:
      'Apple SD Gothic Neo, Spoqa Han Sans, Noto Sans Korean, Apple Gothic, NanumBarunGothic, NanumGothic, Malgun Gothic, sans-serif',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontWeight: 400,
    color: '#444',
    '-webkit-appearance': 'none',
  },

  body: {
    maxHeight: '100vh !important',
    '@media screen and (max-width: 600px)': {
      maxHeight: '100vh !important',
      // @ts-ignore
      maxHeight: '-webkit-fill-available !important',
    },
  },

  'ol, ul': {
    listStyle: 'none',
  },

  'blockquote, q': {
    quotes: 'none',
  },

  'blockquote:before, blockquote:after, q:before, q:after': {
    content: '',
  },

  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },

  a: {
    color: '#222222',
    textDecoration: 'none',
    outline: 'none',
  },

  'img, a': {
    '-webkit-user-drag': 'none',
    '-khtml-user-drag': 'none',
    '-moz-user-drag': 'none',
    '-o-user-drag': 'none',
    userDrag: 'none',
  },

  'input, textarea': {
    '-webkit-user-select': 'text',
    '-khtml-user-select': 'text',
    '-moz-user-select': 'text',
    '-o-user-select': 'text',
    userSelect: 'text',
  },

  /* Chrome, Safari, Edge, Opera */
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  /* Firefox */
  'input[type=number]': {
    '-moz-appearance': 'textfield',
  },

  'input[type="search"]::-webkit-search-decoration,  input[type="search"]::-webkit-search-cancel-button,  input[type="search"]::-webkit-search-results-button,  input[type="search"]::-webkit-search-results-decoration':
    {
      '-webkit-appearance': 'none',
    },

  '.blink-animation': {
    animation: `${blinkEffect} 1s infinite alternate`,
    animationIterationCount: 3,
  },
});

export const { styled, css, theme, getCssText } = createStitches({
  theme: {
    shadows: {
      default: '0 0 7px 2px rgba(180, 180, 180, 0.05)',
      deep01: '0 0 7px 2px rgba(122, 122, 122, 0.12)',
      deep02: '0 7px 12px 3px rgba(61, 61, 61, 0.15)',
      deep03: '0px 0px 7px 7px #F4F4F4',
    },
    colors: {
      black: '#222222',
      black02: '#121212',

      white: '#ffffff',

      gray06: '#3d3d3d',
      gray05: '#7a7a7a',
      gray04: '#b4b4b4',
      gray03: '#c8c8c8',
      gray02: '#e8e8e8',
      gray01: '#f2f2f2',
      gray00: '#f8f8f8',

      primary03: '#eaf2ff',
      primary02: '#dbe9ff',
      primary01: '#2c7fff',

      red: '#e74d5a',
      red03: '#fef2f3',
      vividRed: '#ff385c',

      yellow: '#ffbd35',
      yellow02: '#eca20c',
      'yellow-kakao': '#ffd94a',

      green: '#33b34d',

      NAVER_GREEN: '#03c75a',

      lightGreen01: '#caf9f0',

      sejongGreen03: '#92c7b0',
      sejongGreen02: '#b8ddcd',
      sejongGreen01: '#34795c',
      darkSkyBlue: '#4785e5',
      azureBlue: '#2496ff',
      deepBlue: '#1e45ac',
      murkyBlue: '#76849c',

      PAID_ALL: '#33b34d',
      OVER_DEPOSIT: '#006214',
      PAID_PARTIAL: '#ff940e',
      SCHEDULED: '#ffbd35',
      UNPAID: '#e74d5a',
    },
    sizes: {
      MAX_BROWSER_WIDTH: '640px',
      MAX_MOBILE_WIDTH: '360px',
    },
    space: {
      KAKAO_SAFE_AREA: '30px',
      BOTTOM_NAV_HEIGHT: '56px',
    },
  },
  utils: {
    mg: (value: string) => ({
      marginTop: value,
      marginBottom: value,
      marginLeft: value,
      marginRight: value,
    }),
    mt: (value: string) => ({
      marginTop: value,
    }),
    mr: (value: string) => ({
      marginRight: value,
    }),
    mb: (value: string) => ({
      marginBottom: value,
    }),
    ml: (value: string) => ({
      marginLeft: value,
    }),
    mx: (value: string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: string) => ({
      marginTop: value,
      marginBottom: value,
    }),

    pd: (value: string) => ({
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    }),
    pt: (value: string) => ({
      paddingTop: value,
    }),
    pr: (value: string) => ({
      paddingRight: value,
    }),
    pb: (value: string) => ({
      paddingBottom: value,
    }),
    pl: (value: string) => ({
      paddingLeft: value,
    }),
    px: (value: string) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    safeAreaPaddingTop: ({ extraSize = '0px' }: { extraSize?: string }) => {
      return { paddingTop: `calc(env(safe-area-inset-top) + ${extraSize})` };
    },

    safeAreaPaddingBottom: ({ extraSize = '0px' }: { extraSize?: string }) => {
      return { paddingBottom: `calc(env(safe-area-inset-bottom) + ${extraSize})` };
    },

    safeAreaPadding: ({
      top = false,
      bottom = false,
      left = false,
      right = false,
      extraSize = '0px',
    }: {
      top?: boolean;
      bottom?: boolean;
      left?: boolean;
      right?: boolean;
      extraSize?: string;
    }) => {
      let safeAreaStyle = {};

      if (top) {
        safeAreaStyle = {
          ...safeAreaStyle,
          paddingTop: `calc(env(safe-area-inset-top) + ${extraSize})`,
        };
      }

      if (bottom) {
        safeAreaStyle = {
          ...safeAreaStyle,
          paddingBottom: `calc(env(safe-area-inset-bottom) + ${extraSize})`,
        };
      }

      if (left) {
        safeAreaStyle = {
          ...safeAreaStyle,
          paddingLeft: `calc(env(safe-area-inset-left) + ${extraSize})`,
        };
      }

      if (right) {
        safeAreaStyle = {
          ...safeAreaStyle,
          paddingRight: `calc(env(safe-area-inset-right) + ${extraSize})`,
        };
      }

      return safeAreaStyle;
    },

    safeAreaMarginTop: ({ extraSize = '0px' }: { extraSize?: string }) => {
      return { marginTop: `calc(env(safe-area-inset-top) + ${extraSize})` };
    },

    safeAreaMarginBottom: ({ extraSize = '0px' }: { extraSize?: string }) => {
      return { marginBottom: `calc(env(safe-area-inset-bottom) + ${extraSize})` };
    },

    safeAreaMargin: ({
      top = false,
      bottom = false,
      left = false,
      right = false,
      extraSize = '0px',
    }: {
      top?: boolean;
      bottom?: boolean;
      left?: boolean;
      right?: boolean;
      extraSize?: string;
    }) => {
      let safeAreaStyle = {};

      if (top) {
        safeAreaStyle = {
          ...safeAreaStyle,
          marginTop: `calc(env(safe-area-inset-top) + ${extraSize})`,
        };
      }

      if (bottom) {
        safeAreaStyle = {
          ...safeAreaStyle,
          marginBottom: `calc(env(safe-area-inset-bottom) + ${extraSize})`,
        };
      }

      if (left) {
        safeAreaStyle = {
          ...safeAreaStyle,
          marginLeft: `calc(env(safe-area-inset-left) + ${extraSize})`,
        };
      }

      if (right) {
        safeAreaStyle = {
          ...safeAreaStyle,
          marginRight: `calc(env(safe-area-inset-right) + ${extraSize})`,
        };
      }

      return safeAreaStyle;
    },

    safeAreaTop: ({ extraSize = '0px' }: { extraSize?: string }) => {
      return { top: `calc(env(safe-area-inset-top) + ${extraSize})` };
    },

    safeAreaBottom: ({ extraSize = '0px' }: { extraSize?: string }) => {
      return { bottom: `calc(env(safe-area-inset-bottom) + ${extraSize})` };
    },

    flexRow: ({
      justifyContent = 'center',
      alignItems = 'center',
    }: {
      justifyContent?: string;
      alignItems?: string;
    }) => ({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: justifyContent,
      alignItems: alignItems,
    }),
    flexCol: ({
      justifyContent = 'center',
      alignItems = 'center',
    }: {
      justifyContent?: string;
      alignItems?: string;
    }) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: justifyContent,
      alignItems: alignItems,
    }),
    inlineFlexCol: ({
      justifyContent = 'center',
      alignItems = 'center',
    }: {
      justifyContent?: string;
      alignItems?: string;
    }) => ({
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: justifyContent,
      alignItems: alignItems,
    }),
    inlineFlexRow: ({
      justifyContent = 'center',
      alignItems = 'center',
    }: {
      justifyContent?: string;
      alignItems?: string;
    }) => ({
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: justifyContent,
      alignItems: alignItems,
    }),

    ellipsis: () => ({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),

    typography: (variant: TTypographyVariant) => TYPOGRAPHY_VARIANTS[variant],
  },
  media: {
    desktop: '(min-width: 641px)',
    mobile: 'screen and (max-width: 640px)',
    mobileMin: '(max-width: 359px)',
  },
  prefix: 'z',
});
